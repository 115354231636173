import React, { useState, useEffect } from 'react'
import Burger from './components/menu/Burger'
import { MobileMenu, DesktopMenu } from './components/menu/Menu'
import { Routes, Route } from 'react-router-dom'
import CaseStudies from './pages/CaseStudies'
import Home from './pages/Home'
import WorkHistory from './pages/WorkHistory'
import Contact from './pages/Contact'
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme } from './styles/theme'
import { GlobalStyles } from './styles/global'
import { useLightMode } from './hooks/useLightMode'
import BabylonHealth from 'pages/caseStudies/BabylonHealth'
import HomeOffice from 'pages/caseStudies/HomeOffice'
import NewLook from 'pages/caseStudies/NewLook'
import Principles from 'pages/Principles'
import FF from 'pages/caseStudies/FF'
import ReactGA from 'react-ga4'

export default function App() {
  const [showMenu, setShowMenu] = useState(false)
  const [theme, toggleTheme] = useLightMode()
  ReactGA.initialize('G-2208B2B7RK')

  const openMenu = () => {
    setShowMenu(prev => !prev)
  }

  useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
  }, [])

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='case-studies' element={<CaseStudies />} />
        <Route path='case-studies/babylon-health' element={<BabylonHealth theme={theme} />} />
        <Route path='case-studies/the-home-office' element={<HomeOffice theme={theme} />} />
        <Route path='case-studies/new-look' element={<NewLook theme={theme} />} />
        <Route path='case-studies/fantasy-football' element={<FF theme={theme} />} />
        {/* <Route
          path='case-studies/myles-away'
          element={<MylesAway theme={theme} />}
        /> */}
        <Route exact path='work-history' element={<WorkHistory />} />
        <Route exact path='principles' element={<Principles />} />
        <Route exact path='contact' element={<Contact chatTheme={theme} />} />
      </Routes>
      <MobileMenu
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        openMenu={openMenu}
        toggleTheme={toggleTheme}
        theme={theme}
      />
      <DesktopMenu theme={theme} toggleTheme={toggleTheme} />
      <Burger onClick={openMenu} showMenu={showMenu} setShowMenu={setShowMenu} />
    </ThemeProvider>
  )
}
