import React from 'react'
import {
  LayoutWrapper,
  Back,
  Role,
  Header,
  Intro,
  Logo,
  BodyGrid,
  SummaryGrid,
  SummaryBox,
  SectionHeader,
  Paragraph,
  Emphasis,
  Image,
  BulletList,
} from './casestudies.styled'
import { caseStudyData } from 'data/data'
import { Link } from 'react-router-dom'
import CaseStudyFooter from 'components/caseStudies/CaseStudyFooter'

export default function HomeOffice({ theme }) {
  const caseStudyIndex = 1
  const caseStudyId = caseStudyData[caseStudyIndex].id
  const caseStudyHeadline = caseStudyData[caseStudyIndex].headline

  return (
    <>
      <LayoutWrapper caseStudy>
        <Back>
          <Link to='/case-studies' style={{ textDecoration: 'none' }}>
            Case Studies / The Home Office{' '}
          </Link>
        </Back>

        <Header>{caseStudyHeadline}</Header>

        <Role>
          <Emphasis>Research · 4 min read</Emphasis>
        </Role>

        <Intro>
          <Logo src={`/img/logos/Home_Office_Logo_${theme}.png`} />
          The Home Office is a ministerial department of the Government of the United Kingdom
          responsible for immigration, security, and law and order.
        </Intro>
        <SummaryGrid>
          <SummaryBox borderRight>
            <span>What we did</span>
            <br />
            Worked in a tight cross-functional team to run research, design and develop a prototype
            for the end-to-end EU settled service application.
          </SummaryBox>
          <SummaryBox borderRight>
            <span>What we achieved</span>
            <br />
            Helped deliver the EU Settlement service that enabled 8 million EU citizens to apply to
            remain in the UK following Brexit.
          </SummaryBox>
          <SummaryBox>
            <span>What I personally learned</span>
            <br />
            It’s your duty as a researcher to seek support and guidance when researching an
            emotionally charged subject (immigration status) with vulnerable cohorts.
          </SummaryBox>
        </SummaryGrid>

        <BodyGrid>
          <SectionHeader>Background</SectionHeader>

          <Paragraph>
            On June 23rd 2016 the UK voted to leave the European Union. As a result, millions of EU
            citizens who were living and working in the UK at the time, needed to apply to remain in
            the UK. A scheme was devised called the <Emphasis>‘EU Settled Status Scheme’</Emphasis>{' '}
            that gave EU citizens a pathway to settled status, and the right to remain and work in
            the UK indefinitely.
            <br />
            <br />I joined the team in May 2018, as a senior researcher in a cross functional unit
            (alongside interaction and content design) responsible for the core application.
          </Paragraph>
        </BodyGrid>

        <Image src='/img/theHomeOffice/theHO.jpg' height='var(--large-image)' position='50% 30%' />

        <BodyGrid>
          <SectionHeader>The service</SectionHeader>

          <Paragraph>
            For users, the application process began with a requirement to verify their identity.
            Typically this would require them to mail in their passport, and wait for it to be
            returned, which could be weeks, however prior research had highlighted that this was a
            particularly unusual process for many Europeans who were not accustomed to letting their
            passport out of their sight.
            <br />
            <br />
            So a relatively new and novel technology was to be trialed, that enabled applicants to
            verify their identity using a mobile phone’s RFID technology, and their camera to take a
            picture of their face. However there were a number of constraints in using this feature
            at the time – one of which was that Apple had not given the developers permission to
            make use of the RFID technology on iOS – which inevitably led to confusion, and would
            require effective messaging to alleviate fears.
            <br />
            <br />
            Following verification, applicants would then have to complete a form consisting of
            approximately 20 steps, before finally submitting their application for a caseworker to
            review.
          </Paragraph>
        </BodyGrid>

        <BodyGrid>
          <SectionHeader>What we did</SectionHeader>

          <Paragraph>
            <h4>Rapid testing and evaluation</h4>
            Policy and rules about the scheme were changing so frequently that we knew we needed to
            be as adaptable and nimble with our approach as possible. We decided we would run rapid
            testing in two week sprints, where each session would include some upfront generative
            research to better understand our cohorts and their needs, while testing the current
            thinking around the application.
            <br />
            <br />
            Some of the key question we had to answer with research were:
            <BulletList>
              <li>
                How successful would users with low levels of English proficiency be in completing
                the end-to-end application, understanding important concepts such as dual
                citizenship and the right to work?
              </li>{' '}
              <li>
                How successful would users with access needs be in getting through the application
                using certain assistive technologies such as screen readers?
              </li>
              <li>
                {' '}
                How successful would users who measured low on the digital inclusion scale (a scale
                the UK gov used to measure digital proficiency) be in completing the digital
                verification portion of the application? What support services would they need?
              </li>
            </BulletList>
            Following verification, applicants would then have to complete a form consisting of
            approximately 20 steps, before finally submitting their application for a caseworker to
            review.
            <br />
            <br />
            <h4>Private beta launch</h4>
            In the run up to the public launch of the scheme, various private beta drop-in sessions
            were held at sites around the UK, where the teams responsible for delivering the
            application could watch and observe the first participants work through the application,
            and ask questions about any challenges they encountered. This step was critical in
            ironing out any final issues as well as learning about edge cases that were not picked
            up in the research.
          </Paragraph>
        </BodyGrid>

        <Image
          src='/img/theHomeOffice/euss_example.jpg'
          height='var(--large-image)'
          position='50% 30%'
        />

        <BodyGrid>
          <SectionHeader>What we learned</SectionHeader>

          <Paragraph>
            Many of the key insights remain confidential and cannot be shared here, however there
            were some broader themes that emerged that needed attention.
            <br />
            <br />
            Those with lower levels of English comprehension could not easily understand much of the
            initial content in the application, which included many idioms and contractions – which
            were understandable to native English speakers but less so for non-native speakers.
            <br />
            <br />
            We uncovered critical usability issues when testing the digital verification step using
            assistive technology, which had to be escalated.
            <br />
            <br />
            We found that many users who registered low on the digital inclusion scale could not
            complete the application on their own, which led to recommendations for local community
            centers to provide support for applicants.
            <br />
            <br />
            Additionally we learned through our research how fearful and afraid many were for their
            and their family’s future, and how critical our design and content would be to
            communicate the steps of the process in the most clear and consistent manner, to help
            alleviate their anxieties.
          </Paragraph>
        </BodyGrid>
        <BodyGrid>
          <SectionHeader>Challenges</SectionHeader>

          <Paragraph>
            One of the biggest challenges we faced was that, owing to the emotionally charged and
            highly public debate that was going on at the time in UK society, much of the plans for
            the scheme had to be kept strictly confidential. This meant that we were unable to use
            traditional research recruiters to recruit participants, and instead had to use our own
            networks and connections both personally and within the government to find participants.
            <br />
            <br />
            Additionally a challenge was that legislation for the scheme was being debated in
            parliament at the time, which meant that the rules of the scheme were fluid and ever
            changing. This meant that we were frequently testing new content as new rules and
            requirements emerged.
          </Paragraph>
        </BodyGrid>

        <Image src='/img/theHomeOffice/brexit.jpg' height='var(--large-image)' position='50% 30%' />

        <BodyGrid>
          <SectionHeader>The Outcome</SectionHeader>

          <Paragraph>
            Our work, alongside the hard work of so many others across the design and delivery
            functions, led to two successful private beta launches, before being rolled out publicly
            to 8 million applicants (as of 2024). Our work greatly improved applicants'
            understanding of the scheme, their comprehension of certain concepts that would impact
            them, and alleviated many of their fears.
            <br />
            The private beta phase saw 29,987 applications submitted and the feedback from
            applicants on the speed and ease of the application process was positive. Out of 1,330
            applicants who completed a satisfaction survey:
            <BulletList>
              <li>
                77% of applicants said the application form was very or fairly easy to complete
              </li>
              <li>
                70% of applicants agreed that the application form was quicker than expected to
                complete.
              </li>
            </BulletList>
            I grew a lot as a researcher throughout the course of this project, in particular
            learning how to navigate a highly sensitive subject with research participants who were
            anxious and fearful for their future, as well as further developing skills to be
            flexible and amendable to constantly changing priorities.
          </Paragraph>
        </BodyGrid>
      </LayoutWrapper>
      <CaseStudyFooter caseStudyId={caseStudyId} />
    </>
  )
}
