import React from 'react'
import styled from 'styled-components'
import Header from 'components/Header'
import { timelineData, titleData } from 'data/data'
import { LayoutWrapper } from 'styles/global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
// import useGetFBdata from 'hooks/useGetFBData'

const TimelineGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px 5fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'date dot title' 'date dot details';
  @media (min-width: 450px) {
    grid-template-columns: 1fr 30px 10fr;
  }
`
const Date = styled.div`
  grid-area: date;
  font-weight: 700;
  font-size: 0.6em;
  text-align: right;
  padding-right: 10px;
  line-height: 1.5;
  white-space: nowrap;
`
const Dot = styled.div`
  grid-area: dot;
`
const Title = styled.div`
  grid-area: title;
  padding-bottom: 6px;
  margin-top: -2px;
`
const TitleCompany = styled.h3`
  color: ${({ theme }) => theme.textHighlight};
  font-size: 1.4em;
  margin-block-start: -2px;
  margin-block-end: 2px;
  line-height: 1;
  a {
    color: ${({ theme }) => theme.textHighlight};
    text-decoration: none;
    &:hover {
      text-decoration: underline 2px;
      text-underline-offset: 1px;
    }
  }
`
const TitleCompanyDescription = styled.div`
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #a9a9a9;
`
const TitleCompanyLocation = styled.div`
  font-size: 0.7em;
  font-weight: 700;
  color: #a9a9a9;
`
const TitleRole = styled.h4`
  color: ${({ theme }) => theme.textHighlight};
  margin-block-start: 0;
  margin-block-end: 2px;
  line-height: 1.2;
`
const Details = styled.div`
  grid-area: details;
  line-height: 1.3;
  font-size: 1em;
  padding-bottom: 50px;
`
const PointContainer = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
`
const ElipseOne = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  border-radius: 50%;
  z-index: 2;
  background: ${({ theme }) => theme.timelineDot};
`
const Line = styled.div`
  border-left: ${({ theme }) => theme.timeline};
  height: 100%;
  left: 50%;
  margin-left: 6px;
  top: 16px;
  z-index: 1;
  margin-top: 5px;
`
const EarlyJobs = styled.div`
  font-size: 0.8em;
  color: #a9a9a9;
`
const List = styled.ul`
  padding: 0 0 0 15px;
  list-style-type: none;
`
const Bullets = styled.li`
  padding: 0 0 15px 0;
  :before {
    content: '- ';
    position: absolute;
    margin-left: -15px;
  }
`

const TimelineEntry = ({ entry }) => {
  return (
    <TimelineGrid>
      <Date>
        <div>{entry.date.from}</div>
        <div>{entry.date.to}</div>
      </Date>
      <Dot>
        <PointContainer>
          <ElipseOne />
        </PointContainer>
        {entry.id !== 1 && <Line />}
      </Dot>
      <Title>
        {entry.title.company && (
          <TitleCompany>
            <a href={entry.title.link} target='_blank' rel='noreferrer'>
              {entry.title.company}
            </a>
          </TitleCompany>
        )}
        {entry.title.description && (
          <TitleCompanyDescription>
            <p>{entry.title.description}</p>
          </TitleCompanyDescription>
        )}

        <TitleRole>{entry.title.role}</TitleRole>
        <TitleCompanyLocation>{entry.title.location}</TitleCompanyLocation>
      </Title>
      <Details>
        <List>
          {entry.details.map(bullet => (
            <Bullets key={bullet}>{bullet}</Bullets>
          ))}
        </List>
        <br />
        {entry.caseStudy && (
          <>
            <a href={entry.caseStudy}>Read the case study</a>
            <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faLongArrowAltRight} />
          </>
        )}
      </Details>
    </TimelineGrid>
  )
}

const timelinecomponents = timelineData.map(entry => <TimelineEntry key={entry.id} entry={entry} />)

export default function WorkHistory() {
  // const { allData } = useGetFBdata()
  // console.log(allData)
  return (
    <>
      <Header text={titleData[1].title} />
      <LayoutWrapper workHistory>
        {timelinecomponents}
        <EarlyJobs>
          Jobs before 2009 include: Senior Digital Producer @ Tag Worldwide (USA), Account Manager @
          Tag Worldwide (UK), Account Executive @ The Pulse Group (UK), and Snowboarder by day /
          Banquet Bartender by night @ The Fairmont Chateau Whistler (Canada).
        </EarlyJobs>
      </LayoutWrapper>
    </>
  )
}
