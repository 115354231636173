import React from 'react'
import {
  LayoutWrapper,
  Back,
  Role,
  Header,
  Intro,
  Logo,
  BodyGrid,
  SummaryGrid,
  SummaryBox,
  SectionHeader,
  Paragraph,
  Emphasis,
  Image,
  BulletList,
} from './casestudies.styled'
import { caseStudyData } from 'data/data'
import { Link } from 'react-router-dom'
import CaseStudyFooter from 'components/caseStudies/CaseStudyFooter'

export default function NewLook({ theme }) {
  const caseStudyIndex = 2
  const caseStudyId = caseStudyData[caseStudyIndex].id
  const caseStudyHeadline = caseStudyData[caseStudyIndex].headline

  return (
    <>
      <LayoutWrapper caseStudy>
        <Back>
          <Link to='/case-studies' style={{ textDecoration: 'none' }}>
            Case Studies / New Look
          </Link>
        </Back>

        <Header>{caseStudyHeadline}</Header>

        <Role>
          <Emphasis>Research & Service Design · 4 min read</Emphasis>
        </Role>

        <Intro>
          <Logo src={`/img/logos/NewLook_Logo_${theme}.png`} />
          New Look is a fast fashion retailer headquartered in the UK. In 2017 their global revenue
          from 900 stores and e-commerce sales was £1.5 billion.
        </Intro>
        <SummaryGrid>
          <SummaryBox borderRight>
            <span>What I did</span>
            <br />
            Built a research practice from ground up while delivering impactful programs of
            research.
          </SummaryBox>
          <SummaryBox borderRight>
            <span>What I achieved</span>
            <br />
            Helped establish and grow a more user centered, data driven culture that put the
            customer at the heart of all design decisions.
          </SummaryBox>
          <SummaryBox>
            <span>What I learned</span>
            <br />
            Positioning research insights to support and drive product decisions at a strategic
            level has to be done in the language of the audience you want to influence.
          </SummaryBox>
        </SummaryGrid>

        <BodyGrid>
          <SectionHeader>Background</SectionHeader>

          <Paragraph>
            New Look is a British multi-channel fashion retailer, selling womenswear, menswear and
            clothing for teens. Founded in 1969, it has long been a mainstay on UK high streets and
            in malls. Owing to a strong foundation in bricks and mortar retail, the company was slow
            to adapt to e-commerce best practices and lagged behind larger online only retailers
            like ASOS.
            <br />
            <br />
            In early 2017, New Look hired the head of UX from Burberry to revamp their ecom
            offering. As part of that revamp, I was brought in as the first ever UX Researcher at
            the company.
            <br />
            <br />
            When I arrived at New Look I found a design team that had been running some light
            research, however it was primarily in the shape of surveys, which were often dangerously
            being used to gather self reported behavioral insights to justify business decisions.
            Additionally the company was spending thousands of pounds a month on a one-stop-shop
            research tool that was not delivering a good ROI.
          </Paragraph>
        </BodyGrid>

        <Image
          src='/img/newlook/new_look_store.jpg'
          height='var(--large-image)'
          position='50% 30%'
        />

        <BodyGrid>
          <SectionHeader>Laying the foundations</SectionHeader>

          <Paragraph>
            <h4>Budget</h4>
            The first changes I made were to move away from the one-stop-shop research tool and
            establish a research budget that would support a broad tool kit of mixed methods and
            research techniques. This included establishing a budget for participant recruitment and
            incentivising, building out a toolkit that supported quantitative and qualitative
            methods, as well as democratizing research in the org.
            <br />
            <br />
            <h4>Data management and compliance</h4>
            In June 2017 we were just under a year away from GDPR being a requirement so it was
            important to ensure our research practices were compliant. I put in place governance
            controls to ensure participant data was tracked, stored and processed securely,
            anonymised where possible, and deleted if requested.
            <br />
            <br />
            <h4>Cultivating UCD maturity</h4>
            When I arrived at the company the product team’s user centered maturity was low, so I
            set about working to get as many of the team as well as stakeholders from the business
            involved in research.
            <br />
            <br />
            I set up bi-weekly lab sessions, to encourage product managers and designers to schedule
            tests or get questions answered from a rolling pool of participants. This frequency and
            ease of access to customers greatly increased engagement in research.
            <br />
            <br />
            I would frequently run guerilla testing in-stores to get mobile prototypes into the
            hands of users. In doing so I would always take a designer or product manager with me to
            both support but also get them exposed to customers and to start to understand their
            needs.
            <br />
            <br />
            <h4>Developing a culture of experimentation</h4>
            I worked closely with the product analytics and optimisation team at New Look, and
            together we built a framework to help identify problems across various funnels and key
            parts of the product using quantitative and qualitative data, as well as a hypothesis
            driven measurement strategy to assess the impact we were having through our product and
            design decisions. This worked extremely well in not only motivating the design teams by
            giving them metrics to aim at, but also encouraging a culture of experimentation where
            it was safe to try out ideas, and learn from the tight feedback loops provided.
            <br />
            <br />
            <h4>Building relationships with stakeholders</h4>A huge part of getting research to be
            heard and actioned in a complex organization is to establish relationships with key
            stakeholders throughout the business. I found that building relationships on the retail
            side, as well as marketing, not only helped open doors to bigger pools of participants,
            but it also amplified the reach of UX research at the company.
          </Paragraph>
        </BodyGrid>

        <Image src='/img/newlook/newlook_imac.jpg' height='var(--large-image)' position='50% 30%' />

        <BodyGrid>
          <SectionHeader>Projects</SectionHeader>

          <Paragraph>
            Once the foundations of a research practise were in place, I delivered a number of
            impactful studies that helped guide the direction of initiatives, including:
            <BulletList>
              <li>
                <strong>The 'Buy The Look' Discovery</strong> sought to gather insight to guide an
                initiative already in flight, that allowed people to buy an entire outfit seen on a
                model in one click. I adopted an ethnographic approach by visiting target users at
                their homes to explore home shopping habits, and observe how people shop and build
                outfits. The insights from the study contradicted some of the core tenets of the
                planned initiative leading the team to rethink the feature's value proposition.
              </li>
              <li>
                <strong>The global navigation</strong> had not been updated in a number of years. It
                looked tired and outdated, and there was a push from senior product folks to deliver
                something new. Working alongside the analytics and optimisation team I ran usability
                tests and monitored session recordings, and together we identified areas in the
                existing navigation that were underutilized and confusing, and added to the user's
                cognitive load. I ran a series of card sorting and tree testing sessions to
                benchmark the existing navigation and architecture against a new and improved
                architecture, and supported the deisgn team with usability testing throughout the
                design cycle. Much of the global navigation that we shipped in 2017 is still in
                place in 2024.
              </li>
              <li>
                <strong>The Click and Collect in-store experience</strong> was considered a growth
                area to drive footfall to stores and to bridge the gap between the ecommerce and
                retail worlds. However the data indicated that the number of customers using the
                service were low, so I was tasked to look at the end-to-end journey to assess areas
                where it could be improved. After running a series of observation sessions and
                shop-alongs with customers, I created a service blueprint that outlined the
                end-to-end Click and Collect experience including all digital and physical
                touchpoints. This highlighted key pain points in the existing experience such as
                confusing language at the checkout and poor signage in-store which in turn led to
                frustrations and low engagement.
              </li>
            </BulletList>
          </Paragraph>
        </BodyGrid>

        <Image
          src='/img/newlook/newlook_workshop.jpg'
          height='var(--large-image)'
          position='50% 30%'
        />

        <BodyGrid>
          <SectionHeader>Challenges</SectionHeader>

          <Paragraph>
            The biggest challenge I faced at New Look was positioning research insights to support
            and drive product decisions at a strategic level. I had great success using research to
            evaluate tactical design decisions and promote a culture of design iteration, however
            when it came to generative research insights guiding higher level strategic product
            decisions, my efforts fell short. A desire to keep up with the competition often
            outweighed the desire to be guided by what our customers really needed. The learning I
            took from this was the importance of communicating insights in a language that resonated
            with product leadership.
          </Paragraph>
        </BodyGrid>

        <BodyGrid>
          <SectionHeader>Outcome</SectionHeader>

          <Paragraph>
            Building a research function from the ground up was an incredibly difficult but
            rewarding experience. I learned a lot about the practicalities of getting a research
            function well oiled and working – such as the value of having a trusted pipeline of
            participants, adopting the right tools that make it easier for stakeholders to be part
            of research, and of building belief in the value of qualitative data-driven design so
            insights are acted upon.
            <br />
            <br />
            When I left the company I believe I did so in a state of greater user-centered maturity
            than when I had arrived. I had built practices and processes that are still in use
            today, and I had helped instill a mindset that starting with user needs, is a shortcut
            to successful product development and growth.
          </Paragraph>
        </BodyGrid>
      </LayoutWrapper>
      <CaseStudyFooter caseStudyId={caseStudyId} />
    </>
  )
}
