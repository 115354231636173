export const darkTheme = {
  body: 'var(--color-dark)',
  text: 'var(--color-light)',
  textHighlight: 'var(--color-primary)',
  callout: 'var(--color-dark-callout)',
  buttonGradient: 'linear-gradient(to top left, #e52e71, var(--color-primary))',
  buttonText: 'black',
  elipseOneGradient:
    'radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.5) 100%)',
  elipseTwoGradient: 'linear-gradient(to top left, #e57a2e, var(--color-primary))',
  timeline: '1px solid #414141',
  timelineDot: ' #414141',
  burger: 'var(--color-light)',
  burgerBorder: '3px solid var(--color-light)',
  burgerBackground: '#00000099',
  themeToggleBackground: 'linear-gradient(90deg,rgba(255, 255, 255, 1) 50%,rgba(0, 0, 0, 1) 50%);',
  themeToggleBorder: 'solid white',
  navBackground: '#16161699',
  caseStudySummaryBackground: 'black',
  imageFilter: 'brightness(60%) grayscale(50%)',
  caseStudyFooterTint: 'var(--color-dark-tint)',
}

export const lightTheme = {
  body: 'var(--color-light)',
  text: 'var(--color-dark)',
  textHighlight: 'var(--color-secondary)',
  callout: 'var(--color-light-callout)',
  buttonGradient: 'linear-gradient(to top left,#712bbf,var(--color-secondary))',
  buttonText: 'white',
  elipseOneGradient:
    'radial-gradient(50% 50% at 50% 50%,rgb(0 0 0 / 0%) 0%,rgb(207 207 207 / 50%) 100%)',
  elipseTwoGradient: 'linear-gradient(to top left, #a5e6ff, var(--color-secondary))',
  timeline: '1px solid #e3e3e3',
  timelineDot: ' #e3e3e3',
  burger: 'var(--color-dark)',
  burgerBorder: '3px solid var(--color-dark)',
  burgerBackground: '#e9e9e999',
  themeToggleBackground: 'linear-gradient(90deg,rgba(0, 0, 0, 1) 50%,rgba(255, 255, 255, 1) 50%);',
  themeToggleBorder: 'solid black',
  navBackground: '#f5f5f599',
  caseStudySummaryBackground: 'white',
  imageFilter: 'brightness(100%) grayscale(0%)',
  caseStudyFooterTint: 'var(--color-light-tint)',
}
