import React from 'react'
import styled from 'styled-components'
import Button from 'components/Button'
import TextExpand from 'components/TextExpand'
import { LayoutWrapper } from 'styles/global'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

const LandingCopy = styled.p`
  font-weight: 600;
  font-family: var(--secondary-font);
  font-size: clamp(1.8rem, 7vmin, 2.5rem);
  line-height: 1.2em;
  margin-bottom: 1em;
`
const Yellow = styled.a`
  color: ${({ theme }) => theme.textHighlight};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export default function Home() {
  let navigate = useNavigate()
  const handleClick = () => {
    navigate('/work-history')
  }
  ReactGA.send({ hitType: 'pageview', page: '/', title: 'Home' })

  return (
    <LayoutWrapper home>
      <LandingCopy>
        <Yellow href='https://www.linkedin.com/in/jamestaylorson/' target='_blank'>
          James Taylorson
        </Yellow>{' '}
        is a human-centered leader, practitioner, &amp; educator, based in New York.
      </LandingCopy>

      <LandingCopy>
        He is currently a Director of UX Research at{' '}
        <Yellow href='https://www.brandwatch.com/' target='_blank'>
          Brandwatch
        </Yellow>
        .
      </LandingCopy>
      <TextExpand />
      <Button onClick={handleClick}>Work history</Button>
    </LayoutWrapper>
  )
}
