import React from 'react'
import {
  LayoutWrapper,
  Back,
  Role,
  Header,
  Intro,
  Logo,
  BodyGrid,
  SummaryGrid,
  SummaryBox,
  SectionHeader,
  Paragraph,
  Emphasis,
  Image,
  BulletList,
} from './casestudies.styled'
import { caseStudyData } from 'data/data'
import { Link } from 'react-router-dom'
import CaseStudyFooter from 'components/caseStudies/CaseStudyFooter'

export default function FF({ theme }) {
  const caseStudyIndex = 3
  const caseStudyId = caseStudyData[caseStudyIndex].id
  const caseStudyHeadline = caseStudyData[caseStudyIndex].headline

  return (
    <>
      <LayoutWrapper caseStudy>
        <Back>
          <Link to='/case-studies' style={{ textDecoration: 'none' }}>
            Case Studies / Fantasy Football App
          </Link>
        </Back>

        <Header>{caseStudyHeadline}</Header>

        <Role>
          <Emphasis>Research, Design, Development · 3 min read</Emphasis>
        </Role>

        <Intro>
          <Logo src={`/img/logos/FF_logo_${theme}.png`} />
          The FF app is a non-commercial just-for-fun PWA that enables me and my friends to play
          Fantasy Football (soccer) in a closed league, working on the premise that only one player
          can be owned by one manager.
        </Intro>
        <SummaryGrid>
          <SummaryBox borderRight>
            <span>What I did</span>
            <br />
            Managed the end to end process from design through to build for a Fantasy Football
            Progressive Web App.
          </SummaryBox>
          <SummaryBox borderRight>
            <span>What I achieved</span>
            <br />
            I've nurtured an engaged userbase of a whopping 24 users (!) with all 24 being daily
            active users (for the most part).
          </SummaryBox>
          <SummaryBox>
            <span>What I learned</span>
            <br />
            Learned the ins-and-outs of ReactJS while also greatly developing my javascript
            knowledge.
          </SummaryBox>
        </SummaryGrid>

        <BodyGrid>
          <SectionHeader>Background</SectionHeader>

          <Paragraph>
            I was invited to join a fantasy football league that some friends had been playing
            together since 1995. Owing to the unique format that they played, they could not use the
            common fantasy football platforms, so they had been running it somewhat manually for
            years, sending around spreadsheets and transferring players by email. Additionally it
            was taking two people about 5-hours every weekend to calculate scores and circulate
            updates.
            <br />
            <br />
            I played for a season in the manual format, and during that season I thought things
            could be made much simpler and more efficient if there was one single place where
            everything could be managed, and the admins could be supported by some automated
            processes.
            <br />
            <br />I set about designing and building the first iteration of the Fantasy Football
            Progressive Web App.
          </Paragraph>
        </BodyGrid>

        <Image src='/img/ff/FF20.jpg' height='var(--large-image)' position='50% 50%' />

        <BodyGrid>
          <SectionHeader>The First iteration</SectionHeader>
          <Paragraph>
            The first iteration was built using plain vanilla javascript, HTML and CSS. It used the
            Google Sheets API to turn a Google Sheet into a back-end database with CRUD
            capabilities, and used Google Apps script to run a series of functions at set intervals
            using programmatic triggers, much like cron jobs.
            <br />
            <br />
            This version of the app was relatively rudimentary in both form and function, but it did
            the trick and immediately started to make the game more engaging with lower admin
            overhead.
          </Paragraph>
        </BodyGrid>

        <Image src='/img/ff/FF21.jpg' height='var(--large-image)' position='50% 50%' />

        <BodyGrid>
          <SectionHeader>The Second iteration</SectionHeader>
          <Paragraph>
            The second iteration was built using ReactJS. It still ran on the same google sheets
            backend, but now Google had moved from their v3 API to v4 which required authentication
            using JSON Web Tokens. Owing to this it made sense to use something for managing,
            caching and syncing remote data to reduce the number of calls to the sheet (which Google
            caps), so I looked to React Query by TanStack. This significantly reduced the number of
            required calls and made the whole experience seem lightning fast.
            <br />
            <br />
            In addition I incorporated user accounts for the first time using Firebase for
            authentication. This added a whole new dimension to the app making it not only more
            secure but also a much more personalized experience.
          </Paragraph>
        </BodyGrid>

        <Image src='/img/ff/FF24.jpg' height='var(--large-image)' position='50% 50%' />

        <BodyGrid>
          <SectionHeader>The Third iteration</SectionHeader>
          <Paragraph>
            The third iteration saw a full design overhaul leaning into more modern UI styles, more
            reusable patterns, and it made much more of users avatars to increase not only a sense
            of ownership over players, but also competition between managers.
          </Paragraph>
        </BodyGrid>

        <BodyGrid>
          <SectionHeader>The Outcome</SectionHeader>
          <Paragraph>
            After a successful few years playing with friends in the UK who were the original
            founders of the game, I launched a version in the US to play with US friends and it’s
            been a great success.
            <br />
            <br />
            As an app pretty much runs itself, and this has taken the weekly admin burden from a
            total of 10h to zero, while delivering a fun and engaging experience for 24 users spread
            across two continents.
            <br />
            <br />
            Along the way I have learned the ins-and-outs of ReactJS, learned about the value of
            component based design systems, gained the confidence to throw together quick ideas in
            code, and it has taught me a lot about what makes a product engaging and fun for people.
          </Paragraph>
        </BodyGrid>
      </LayoutWrapper>
      <CaseStudyFooter caseStudyId={caseStudyId} />
    </>
  )
}
